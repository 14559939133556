<script setup lang="ts">
import { Gradient, type GradientOptions } from '@webamboos/stripe-gradient'

const defaultColors = {
  yellow: '#fafbbf',
  teal: '#81f2ea',
  lightTeal: '#d2fbf3',
  white: '#ffffff',
}

const props = defineProps<{
  id: string
  colors?: string[]
  gradientOptions?: Omit<GradientOptions, 'canvas' | 'colors'>
}>()

onMounted(() => {
  try {
    new Gradient({
      canvas: `#${props.id}`,
      colors: props.colors || Object.values(defaultColors),
      ...(props.gradientOptions || {}),
    })
  } catch (error) {
    console.log('Gradient could not render.')
  }
})
</script>

<template>
  <canvas :id="id" />
</template>
